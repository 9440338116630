import { useCallback, useEffect, useState } from 'react';
import { Address, erc20Abi, getContract } from 'viem';

import { GlobalContainer } from '@/context/GlobalContext';
import SymbolInputContext from '@/context/SymbolInputContext';

const { useSymbolInputTracked } = SymbolInputContext;

function useATokenAddress() {
  const { sdk: allstakeSdk, publicClient } = GlobalContainer.useContainer();
  const [symbolInput] = useSymbolInputTracked();

  const [aToken, setAToken] = useState<{
    symbol: string;
    address: string;
    decimals: number;
  }>();

  const handleSetAToken = useCallback(async () => {
    // only support eth
    if (!allstakeSdk || !allstakeSdk.ethereum) return;
    if (!publicClient) return;
    if (!symbolInput?.address) return;
    if (symbolInput?.chain !== 'eth') return;

    const strategyData = await allstakeSdk.ethereum.uiDataProviderContract.strategyDataByToken(
      symbolInput.address,
    );
    const aTokenAddress = strategyData.strategyAddress;

    // get a token address info
    const erc20Contract = getContract({
      address: aTokenAddress as Address,
      abi: erc20Abi,
      client: publicClient,
    });

    const [symbol, decimals] = await Promise.all([
      erc20Contract.read.symbol(),
      erc20Contract.read.decimals(),
    ]);

    setAToken({
      symbol,
      address: aTokenAddress,
      decimals,
    });
  }, [allstakeSdk, publicClient, symbolInput?.chain, symbolInput?.address]);

  useEffect(() => {
    handleSetAToken();
  }, [handleSetAToken]);

  return aToken;
}

export default useATokenAddress;

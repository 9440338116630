import { AllstakeSdk, Amount, BN } from 'allstake-sdk';
import { sharesToBalance } from 'allstake-sdk/dist/ethereum';
import { PropsWithChildren, useEffect, useMemo, useRef } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { Address } from 'viem';
import { useAccount } from 'wagmi';

import config from '@/config';
import { SymbolInput } from '@/config/type';
import { useBalance } from '@/hooks/useBalance';
import useChain from '@/hooks/useChain';
import { useGalleryCards } from '@/hooks/useGalleryCards';
import useQuery from '@/hooks/useQuery';
import { getErc20Balance } from '@/utils/erc20';
import { isChainNativeToken } from '@/utils/regex';
import { SECOND, sleep } from '@/utils/time';
import { getNearTokenBalanceCombine, getSolTokenBalanceCombine } from '@/utils/wallet';
import { useWallet, WalletContextState } from '@solana/wallet-adapter-react';
import { PublicKey } from '@solana/web3.js';

import AssetCollapseContext from './AssetCollapseContext';
import BalanceContext from './BalanceContext';
import GalleryCardsContext from './GalleryCardsContext';
import { GlobalContainer } from './GlobalContext';
import RestakeTabContext from './RestakeTabContext';
import SelectedAssets from './SelectedAssets';
import SelectedNetwork from './SelectedNetwork';
import SymbolInputContext from './SymbolInputContext';
import TokenInputContext from './TokenInputContext';
import { useWalletSelector } from './WalletSelectorContext';

const { GalleryCardsProvider, useGalleryCardsTracked } = GalleryCardsContext;
const { SymbolInputProvider, useSymbolInputTracked } = SymbolInputContext;
const { RestakeTabProvider, useRestakeTabTracked } = RestakeTabContext;
const { TokenInputProvider } = TokenInputContext;
const { BalanceProvider, useBalanceTracked } = BalanceContext;
const { AssetCollapseProvider } = AssetCollapseContext;
const { SelectedAssetsProvider } = SelectedAssets;
const { SelectedNetworkProvider, useSelectedNetworkTracked } = SelectedNetwork;

async function getUserDepositAmount(
  allstakeSdk: AllstakeSdk,
  symbolInput: SymbolInput,
  wallet: WalletContextState,
) {
  if (!allstakeSdk.solana || !allstakeSdk.solana.strategyManagerProgram || !wallet.publicKey) {
    return new BN(0);
  }
  try {
    return await allstakeSdk.solana.strategyManagerProgram.getUserDepositAmount(
      new PublicKey(symbolInput.address),
      wallet.publicKey,
    );
  } catch (e) {
    console.error(e);
    return new BN(0);
  }
}

function ContextInitializer({ children }: PropsWithChildren) {
  const query = useQuery();
  const location = useLocation();
  const { address } = useAccount();
  const solanaWallet = useWallet();
  const ethAccount = useAccount();
  const chain = useChain();
  const { selector, accountId } = useWalletSelector();
  const galleryCardsInHooks = useGalleryCards();

  const { sdk: allstakeSdk } = GlobalContainer.useContainer();

  const [balance, setBalance] = useBalanceTracked();
  const [symbolInput, setSymbolInput] = useSymbolInputTracked();
  const [, setTab] = useRestakeTabTracked();
  const [, setGalleryCards] = useGalleryCardsTracked();
  const [, setSelectedNetwork] = useSelectedNetworkTracked();
  const { refreshBalance, setRefreshBalance } = useBalance();

  const refreshingRef = useRef(false);
  const balanceTimerRef = useRef<any>(null);

  const querySymbol = query.get('symbol');
  const queryChain = query.get('chain');

  const isChainNativeTokenStatus = useMemo(() => {
    if (isChainNativeToken(symbolInput?.name || '')) return true;
    return false;
  }, [symbolInput]);

  useEffect(() => {
    setGalleryCards(galleryCardsInHooks);
  }, [galleryCardsInHooks, setGalleryCards]);

  useEffect(() => {
    if (!symbolInput || !chain) return;
    if (chain?.isSignedIn && symbolInput.chain) {
      setSelectedNetwork([symbolInput.chain]);
    } else {
      setSelectedNetwork(config.dropdownNetworks);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chain?.isSignedIn]);

  //check sign in
  useEffect(() => {
    let token =
      config.restakingTokens.find((token) => config.supportNetworks.includes(token.chain)) ??
      config.restakingTokens[0];

    if (querySymbol) {
      const findToken = config.restakingTokens.find(
        (token: SymbolInput) => token.name === querySymbol,
      );
      if (findToken) {
        token = findToken;
      }
    } else if (queryChain) {
      const findToken = config.restakingTokens.find(
        (token: SymbolInput) => token.chain === queryChain,
      );
      if (findToken) {
        token = findToken;
      }
    }
    setSymbolInput(token);
  }, [
    selector,
    accountId,
    solanaWallet.publicKey,
    ethAccount.address,
    querySymbol,
    queryChain,
    setSymbolInput,
  ]);

  async function _refreshBalance() {
    if (!symbolInput) return;
    if (location.pathname === '/portfolio' && isChainNativeTokenStatus) {
      return;
    }

    if (symbolInput.chain === 'solana') {
      if (
        !allstakeSdk ||
        !allstakeSdk.solana ||
        !allstakeSdk.solana.strategyManagerProgram ||
        !solanaWallet.publicKey
      ) {
        return;
      }

      try {
        const [uiAmount, depositAmount] = await Promise.all([
          getSolTokenBalanceCombine(solanaWallet.publicKey.toBase58(), symbolInput),
          getUserDepositAmount(allstakeSdk, symbolInput, solanaWallet),
        ]);
        const tokenBalance = String(uiAmount);
        return {
          tokenBalance,
          stakedToken: Amount.format(depositAmount.toString(), symbolInput.decimals),
        };
      } catch (e) {
        console.error(e);
        return {
          tokenBalance: '-',
          stakedToken: '-',
        };
      }
    } else if (symbolInput.chain === 'near') {
      if (
        !allstakeSdk ||
        !allstakeSdk.near ||
        !allstakeSdk.near.strategyManagerContract ||
        !accountId
      ) {
        return;
      }
      const [balance, deposits] = await Promise.all([
        getNearTokenBalanceCombine(accountId, symbolInput),
        allstakeSdk.near.strategyManagerContract.getDeposits({
          staker: accountId,
        }),
      ]);
      const depositRaw = deposits.find(
        ([strategy]) => strategy.underlying_token === symbolInput.address,
      );
      const deposit = depositRaw ? depositRaw[1] : '0';
      const tokenBalance = balance;
      const stakedToken = Amount.format(deposit, symbolInput.decimals);
      return {
        tokenBalance,
        stakedToken,
      };
    } else if (symbolInput.chain === 'eth') {
      if (
        !address ||
        !allstakeSdk ||
        !allstakeSdk.ethereum ||
        !allstakeSdk.ethereum.strategyManagerContract
      ) {
        return;
      }
      const [balance, userStrategyData, strategyData] = await Promise.all([
        getErc20Balance(symbolInput.address as Address, address as Address),
        allstakeSdk.ethereum.uiDataProviderContract.userStrategyData(symbolInput.address, address),
        allstakeSdk.ethereum.uiDataProviderContract.strategyDataByToken(symbolInput.address),
      ]);
      const strategyTotalBalance = await getErc20Balance(
        symbolInput.address as Address,
        userStrategyData.strategy as Address,
      );
      const shares = userStrategyData.userShares;
      const tokenBalance = Amount.format(balance.toString(), symbolInput.decimals);
      const stakedTokenRaw = sharesToBalance(shares, {
        strategyTotalBalance,
        strategyTotalShares: strategyData.strategyTotalShares,
      });
      const stakedToken = Amount.format(stakedTokenRaw.toString(), symbolInput.decimals);
      return {
        tokenBalance,
        stakedToken,
      };
    }
  }

  const _handleRefreshBalance = async () => {
    if (refreshingRef.current) return;
    try {
      refreshingRef.current = true;
      if (!symbolInput) return;
      const resp = await _refreshBalance();
      return resp;
    } catch (err) {
      console.warn('refresh balance failed', err);
    } finally {
      refreshingRef.current = false;
    }
  };
  const _handleRefreshBalanceRef = useRef(_handleRefreshBalance);
  _handleRefreshBalanceRef.current = _handleRefreshBalance;
  const _refreshBalanceLoop = async () => {
    if (!symbolInput) return;
    if (!refreshBalance) return;

    try {
      await sleep(0.5 * SECOND);
      const newBalance = await _handleRefreshBalanceRef.current();
      if (!symbolInput) return;
      if (!newBalance) {
        setRefreshBalance(false);
        return;
      }

      if (
        newBalance &&
        (newBalance.stakedToken !== balance[symbolInput.address]?.stakedToken ||
          newBalance.tokenBalance !== balance[symbolInput.address]?.tokenBalance)
      ) {
        setRefreshBalance(false);
        setBalance((prev) => ({
          ...prev,
          ...{
            [symbolInput.address]: newBalance,
          },
        }));
        return;
      }

      balanceTimerRef.current = setInterval(() => {
        _handleRefreshBalanceRef.current().then((newBalance2) => {
          if (!refreshBalance) {
            clearInterval(balanceTimerRef.current);
            balanceTimerRef.current = null;
            return;
          }
          if (!newBalance) {
            setRefreshBalance(false);
            clearInterval(balanceTimerRef.current);
            balanceTimerRef.current = null;
            return;
          }
          if (
            newBalance2 &&
            (newBalance2.stakedToken !== balance[symbolInput.address]?.stakedToken ||
              newBalance2.tokenBalance !== balance[symbolInput.address]?.tokenBalance)
          ) {
            clearInterval(balanceTimerRef.current);
            balanceTimerRef.current = null;

            setRefreshBalance(false);
            setBalance((prev) => ({
              ...prev,
              ...{
                [symbolInput.address]: newBalance2,
              },
            }));
          }
        });
      }, 2 * SECOND);
    } catch (err) {
      setRefreshBalance(false);
      clearInterval(balanceTimerRef.current);
      balanceTimerRef.current = null;
      console.warn(err);
    }
  };
  const _refreshBalanceLoopRef = useRef(_refreshBalanceLoop);
  _refreshBalanceLoopRef.current = _refreshBalanceLoop;

  useEffect(() => {
    if (!refreshBalance) return;
    _refreshBalanceLoopRef.current();
  }, [refreshBalance]);

  useEffect(() => {
    if (refreshBalance) return;
    clearInterval(balanceTimerRef.current);
    balanceTimerRef.current = null;
  }, [refreshBalance]);

  useEffect(() => {
    return () => {
      clearInterval(balanceTimerRef.current);
      balanceTimerRef.current = null;
    };
  }, []);

  useEffect(() => {
    if (!symbolInput || !allstakeSdk) return;
    _handleRefreshBalanceRef
      .current()
      .then((newBalance) => {
        if (newBalance && symbolInput) {
          setBalance((prev) => ({
            ...prev,
            ...{
              [symbolInput.address]: newBalance,
            },
          }));
        }
      })
      .catch((err) => {
        console.warn('refresh balance failed', err);
      });
  }, [symbolInput, allstakeSdk, accountId, ethAccount.address, setBalance]);

  const [searchParams] = useSearchParams();
  const tabParam = searchParams.get('tab') || 'deposit';
  useEffect(() => {
    if (tabParam === 'deposit') {
      setTab('deposit');
    } else {
      setTab('unstake');
    }
  }, [tabParam, setTab]);

  return children;
}

function GlobalStorageContext({ children }: PropsWithChildren) {
  return (
    <SelectedAssetsProvider>
      <SelectedNetworkProvider>
        <AssetCollapseProvider>
          <SymbolInputProvider>
            <GalleryCardsProvider>
              <RestakeTabProvider>
                <TokenInputProvider>
                  <BalanceProvider>
                    <ContextInitializer>{children}</ContextInitializer>
                  </BalanceProvider>
                </TokenInputProvider>
              </RestakeTabProvider>
            </GalleryCardsProvider>
          </SymbolInputProvider>
        </AssetCollapseProvider>
      </SelectedNetworkProvider>
    </SelectedAssetsProvider>
  );
}

export default GlobalStorageContext;

import { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useAccount } from 'wagmi';

import { SymbolInput } from '@/config/type';
import { GlobalContainer } from '@/context/GlobalContext';
import { useWalletSelector } from '@/context/WalletSelectorContext';
import useChain from '@/hooks/useChain';
import { waitForTransactionReceiptRecursion } from '@/utils/erc20';
import { formatToReadableError } from '@/utils/error';
import { isWebWallet } from '@/utils/wallet';
import { PublicKey } from '@solana/web3.js';

export interface CommonWithdrawRequest {
  symbolInput: SymbolInput;
}

export interface UseWithdrawProps {
  onStart?: () => void;
  onSuccess?: () => void;
  onError?: (error: string, showModal: boolean) => void;
  onFinish?: () => void;
}

export function useWithdraw({ onSuccess, onError, onStart, onFinish }: UseWithdrawProps) {
  const { t } = useTranslation();

  const onSuccessRef = useRef(onSuccess);
  onSuccessRef.current = onSuccess;
  const onErrorRef = useRef(onError);
  onErrorRef.current = onError;
  const onStartRef = useRef(onStart);
  onStartRef.current = onStart;
  const onFinishRef = useRef(onFinish);
  onFinishRef.current = onFinish;

  const chain = useChain();
  const { sdk: allstakeSdk, wallet, publicClient } = GlobalContainer.useContainer();
  const ethAccount = useAccount();
  const { accountId } = useWalletSelector();

  const onSolanaWithdraw = useCallback(
    async ({ symbolInput }: CommonWithdrawRequest) => {
      if (!wallet?.publicKey) return;
      if (!allstakeSdk || !allstakeSdk.solana?.strategyManagerProgram) {
        return;
      }

      try {
        onStartRef.current?.();
        await allstakeSdk.solana.strategyManagerProgram.completeWithdraw(
          new PublicKey(symbolInput.address),
        );
        onSuccessRef.current?.();
      } catch (e) {
        onErrorRef.current?.(formatToReadableError(e, t), true);
        throw e;
      } finally {
        onFinishRef.current?.();
      }
    },
    [allstakeSdk, wallet, t],
  );

  const onNearWithdraw = useCallback(
    async ({ symbolInput }: CommonWithdrawRequest) => {
      if (!accountId) return;
      if (!allstakeSdk || !allstakeSdk.near?.strategyManagerContract) {
        return;
      }

      onStartRef.current?.();
      const isWebWalletStatus = await isWebWallet();

      try {
        const strategies = await allstakeSdk.near.strategyManagerContract.getStrategies({});
        const withdrawal = await allstakeSdk.near.strategyManagerContract.getQueuedWithdrawals({
          staker: accountId,
        });
        const withdrawals: Array<{
          staker: string;
          operator?: string;
          withdrawer: string;
          nonce: number;
          start_at: number;
          strategies: string[];
          shares: string[];
        }> = [];

        withdrawal.forEach((withdraw) => {
          const targetStrategies: string[] = [];
          const targetShares: string[] = [];
          const targetStrategiesStartAt: number = withdraw.start_at;
          const targetNonce: number = withdraw.nonce;

          const withdrawStrategies = withdraw.strategies;
          const withdrawShares = withdraw.shares;
          withdrawStrategies.forEach((withdrawStrategyId, idx) => {
            const strategy = strategies.find((strategy) => strategy.id === withdrawStrategyId);
            if (strategy && strategy.underlying_token === symbolInput.address) {
              targetStrategies.push(strategy.id);
              targetShares.push(withdrawShares[idx]);
            }
          });

          if (targetStrategies.length > 0 && targetShares.length > 0) {
            withdrawals.push({
              staker: accountId,
              withdrawer: accountId,
              nonce: targetNonce,
              start_at: targetStrategiesStartAt,
              strategies: targetStrategies,
              shares: targetShares,
            });
          }
        });

        await allstakeSdk.near.strategyManagerContract.completeQueuedWithdrawals({
          withdrawals: withdrawals,
        });
        onSuccessRef.current?.();
      } catch (e) {
        onErrorRef.current?.(formatToReadableError(e, t), !isWebWalletStatus);
        throw e;
      } finally {
        onFinishRef.current?.();
      }
    },
    [accountId, allstakeSdk, t],
  );

  const onETHWithdraw = useCallback(
    async ({ symbolInput }: CommonWithdrawRequest) => {
      if (!publicClient) return;
      if (!ethAccount?.address) return;

      if (
        !allstakeSdk ||
        !allstakeSdk.ethereum ||
        !allstakeSdk.ethereum.strategyManagerContract ||
        !allstakeSdk.ethereum.uiDataProviderContract
      ) {
        return;
      }

      try {
        onStartRef.current?.();
        const userStrategyData = await allstakeSdk.ethereum.uiDataProviderContract.userStrategyData(
          symbolInput.address,
          ethAccount.address,
        );
        const requests =
          await allstakeSdk.ethereum.uiDataProviderContract.userQueueWithdrawalRequests(
            symbolInput.address,
            ethAccount.address,
          );
        const txHash =
          await allstakeSdk.ethereum.strategyManagerContract.completeWithdrawFromStrategy(
            userStrategyData.strategy,
            requests.map((request) => BigInt(request.id)),
          );
        await waitForTransactionReceiptRecursion(publicClient, txHash);
        onSuccessRef.current?.();
      } catch (e) {
        onErrorRef.current?.(formatToReadableError(e, t), true);
        throw e;
      } finally {
        onFinishRef.current?.();
      }
    },
    [allstakeSdk, ethAccount, publicClient, t],
  );

  const onWithdraw = useCallback(
    (req: CommonWithdrawRequest) => {
      if (!chain) return;
      if (chain.chainId === 'solana') {
        onSolanaWithdraw(req);
      } else if (chain.chainId === 'near') {
        onNearWithdraw(req);
      } else if (chain.chainId === 'eth') {
        onETHWithdraw(req);
      }
    },
    [chain, onSolanaWithdraw, onNearWithdraw, onETHWithdraw],
  );

  return {
    onSolanaWithdraw,
    onNearWithdraw,
    onETHWithdraw,
    onWithdraw,
  };
}

import { useMemo } from 'react';

import BalanceContext from '@/context/BalanceContext';
import { GlobalContainer } from '@/context/GlobalContext';
import RestakeTabContext from '@/context/RestakeTabContext';
import SymbolInputContext from '@/context/SymbolInputContext';

const { useBalanceTracked } = BalanceContext;
const { useSymbolInputTracked } = SymbolInputContext;
const { useRestakeTabTracked } = RestakeTabContext;

export function useBalance() {
  const [balance] = useBalanceTracked();
  const [symbolInput] = useSymbolInputTracked();
  const [tab] = useRestakeTabTracked();
  const { balanceUpdateFlag, refreshBalance, setRefreshBalance } = GlobalContainer.useContainer();

  const balanceKey = useMemo(() => {
    if (!symbolInput?.address) return;
    const balanceValue = balance[symbolInput.address];
    return `${symbolInput.address}-${balanceValue?.tokenBalance || 0}-${balanceValue?.stakedToken || 0}`;
  }, [symbolInput?.address, balance]);

  const shownBalance = useMemo(() => {
    if (!symbolInput?.address) return;
    if (tab === 'deposit') return balance[symbolInput.address]?.tokenBalance;
    else if (tab === 'unstake') return balance[symbolInput.address]?.stakedToken;
    else return;
  }, [symbolInput?.address, balance, tab]);

  return {
    balanceKey,
    shownBalance,
    refreshBalance,
    balanceUpdateFlag,
    setRefreshBalance,
  };
}

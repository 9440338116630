import { Amount, BN } from 'allstake-sdk';
import { balanceToShares } from 'allstake-sdk/dist/ethereum';
import { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Address } from 'viem';
import { useAccount } from 'wagmi';

import { SymbolInput } from '@/config/type';
import { GlobalContainer } from '@/context/GlobalContext';
import { useWalletSelector } from '@/context/WalletSelectorContext';
import useChain from '@/hooks/useChain';
import {
  approve,
  checkQueueWithdrawInsufficientAllowance,
  getErc20Balance,
  waitForTransactionReceiptRecursion,
} from '@/utils/erc20';
import { formatToReadableError } from '@/utils/error';
import { isWebWallet } from '@/utils/wallet';
import { PublicKey } from '@solana/web3.js';

import { ModalTypes } from './types';

export interface CommonUnstakeRequest {
  shownBalance?: string;
  tokenInput: string;
  symbolInput: SymbolInput;
}

export interface UseUnstakeProps {
  onStart?: () => void;
  onSuccess?: ({
    newTokenInput,
    modalType,
  }: {
    newTokenInput: string;
    modalType: ModalTypes;
  }) => void;
  onError?: (error: string, showModal: boolean) => void;
  onFinish?: () => void;
}

export function useUnstake({ onSuccess, onError, onStart, onFinish }: UseUnstakeProps) {
  const { t } = useTranslation();

  const onSuccessRef = useRef(onSuccess);
  onSuccessRef.current = onSuccess;
  const onErrorRef = useRef(onError);
  onErrorRef.current = onError;
  const onStartRef = useRef(onStart);
  onStartRef.current = onStart;
  const onFinishRef = useRef(onFinish);
  onFinishRef.current = onFinish;

  const chain = useChain();
  const { sdk: allstakeSdk, wallet, publicClient } = GlobalContainer.useContainer();
  const ethAccount = useAccount();
  const { accountId } = useWalletSelector();

  const onSolanaUnstake = useCallback(
    async ({ tokenInput, symbolInput }: CommonUnstakeRequest) => {
      if (!wallet?.publicKey) return;
      if (!allstakeSdk || !allstakeSdk.solana?.strategyManagerProgram) {
        return;
      }

      try {
        onStartRef.current?.();
        await allstakeSdk.solana.strategyManagerProgram.queueWithdraw(
          new PublicKey(symbolInput.address),
          new BN(Amount.parse(tokenInput, symbolInput.decimals)),
        );
        onSuccessRef.current?.({
          newTokenInput: '',
          modalType: 'deposit_success',
        });
      } catch (e) {
        console.error(e);
        onErrorRef.current?.(formatToReadableError(e, t), true);
      } finally {
        onFinishRef.current?.();
      }
    },
    [allstakeSdk, wallet, t],
  );

  const onNearUnstake = useCallback(
    async ({ tokenInput, symbolInput }: CommonUnstakeRequest) => {
      if (!accountId) return;
      if (!allstakeSdk || !allstakeSdk.near?.strategyManagerContract) {
        return;
      }

      onStartRef.current?.();
      const isWebWalletStatus = await isWebWallet();

      try {
        const strategies = await allstakeSdk.near.strategyManagerContract.getStrategies({});
        const strategy = strategies.find(
          (strategy) => strategy.underlying_token === symbolInput.address,
        );
        if (!strategy) {
          return;
        }

        await allstakeSdk.near.strategyManagerContract.queueWithdrawals({
          queuedWithdrawalParams: [
            {
              withdrawer: accountId,
              strategies: [strategy.id],
              shares: [Amount.parse(tokenInput, symbolInput.decimals)],
            },
          ],
        });
        onSuccessRef.current?.({
          newTokenInput: '',
          modalType: 'deposit_success',
        });
      } catch (e) {
        onErrorRef.current?.(formatToReadableError(e, t), !isWebWalletStatus);
        throw e;
      } finally {
        onFinishRef.current?.();
      }
    },
    [allstakeSdk, accountId, t],
  );

  const onETHUnstake = useCallback(
    async ({ shownBalance, tokenInput, symbolInput }: CommonUnstakeRequest) => {
      if (!publicClient) return;
      if (!ethAccount?.address) return;

      if (
        !allstakeSdk ||
        !allstakeSdk.ethereum ||
        !allstakeSdk.ethereum.strategyManagerContract ||
        !allstakeSdk.ethereum.uiDataProviderContract
      ) {
        return;
      }

      try {
        onStartRef.current?.();
        const [userStrategyData, strategyData] = await Promise.all([
          allstakeSdk.ethereum.uiDataProviderContract.userStrategyData(
            symbolInput.address,
            ethAccount.address,
          ),
          allstakeSdk.ethereum.uiDataProviderContract.strategyDataByToken(symbolInput.address),
        ]);

        // format input to raw
        const inputAmount = Amount.parse(tokenInput, symbolInput.decimals);
        const strategyTotalBalance = await getErc20Balance(
          symbolInput.address as Address,
          userStrategyData.strategy as Address,
        );

        // check if click max
        const isMax = shownBalance === tokenInput;
        const shares = isMax
          ? userStrategyData.userShares
          : balanceToShares(BigInt(inputAmount), {
              strategyTotalBalance,
              strategyTotalShares: strategyData.strategyTotalShares,
            });

        const strategyAddress = userStrategyData.strategy as Address;
        if (
          await checkQueueWithdrawInsufficientAllowance(
            strategyAddress,
            ethAccount.address,
            shares.toString(),
          )
        ) {
          await approve(strategyAddress as Address, strategyAddress as Address, publicClient);
        }

        if (
          await checkQueueWithdrawInsufficientAllowance(
            strategyAddress,
            ethAccount.address,
            shares.toString(),
          )
        ) {
          onErrorRef.current?.(t('error.insufficientAllowance'), true);
          return;
        }

        const txHash = await allstakeSdk.ethereum.strategyManagerContract.queueWithdrawFromStrategy(
          userStrategyData.strategy,
          shares,
        );
        await waitForTransactionReceiptRecursion(publicClient, txHash);
        onSuccessRef.current?.({
          newTokenInput: '',
          modalType: 'deposit_success',
        });
      } catch (e) {
        onErrorRef.current?.(formatToReadableError(e, t), true);
        throw e;
      } finally {
        onFinishRef.current?.();
      }
    },
    [allstakeSdk, ethAccount, publicClient, t],
  );

  const onUnstake = useCallback(
    (req: CommonUnstakeRequest) => {
      if (!chain) return;
      if (chain.chainId === 'solana') {
        onSolanaUnstake(req);
      } else if (chain.chainId === 'near') {
        onNearUnstake(req);
      } else if (chain.chainId === 'eth') {
        onETHUnstake(req);
      }
    },
    [chain, onSolanaUnstake, onNearUnstake, onETHUnstake],
  );

  return {
    onSolanaUnstake,
    onNearUnstake,
    onETHUnstake,
    onUnstake,
  };
}

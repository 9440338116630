import { Amount, BigNumber } from 'allstake-sdk';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import config from '@/config';
import { SymbolInput } from '@/config/type';
import { GlobalContainer } from '@/context/GlobalContext';
import { formatTotalDepositAmount, formatTvl } from '@/utils/number';
import { getPriceInUsd } from '@/utils/price';
import { getTokenAmount } from '@/utils/rpc';
import { getNear } from '@/utils/wallet';
import { PublicKey } from '@solana/web3.js';

import { useBalance } from './useBalance';

export interface GalleryCard {
  symbol: string;
  icon: string;
  title: string;
  value: string;
  valueInUsd: string;
  valueInUsdRaw: undefined | BigNumber;
  price: BigNumber | null;
  boostTag: Array<string>;
}

export type GalleryCards = GalleryCard[];

export function useGalleryCards() {
  const { sdk } = GlobalContainer.useContainer();
  const { t } = useTranslation();
  const fetchingRef = useRef(false);

  const { balanceUpdateFlag } = useBalance();

  const [galleryCards, setGalleryCards] = useState<GalleryCards>(() =>
    config.restakingTokens
      .filter((token) => token.showInGallery)
      .map((token: SymbolInput) => {
        return {
          symbol: token.symbol,
          icon: token.icon,
          title: `$${token.symbol} ${t('restake.restaked')}`,
          value: `- ${token.symbol}`,
          valueInUsd: '~$-',
          valueInUsdRaw: undefined,
          price: BigNumber(0),
          boostTag: token.boostTag,
        };
      }),
  );

  const fetchGalleryCards = useCallback(async () => {
    if (!sdk) return;
    const cards = await Promise.all(
      config.restakingTokens
        .filter((token) => token.showInGallery)
        .map(async (token: SymbolInput) => {
          let totalDepositAmount = '0';
          const price = await getPriceInUsd(sdk, token.address, token.symbol);
          if (token.chain === 'solana' && sdk.solana?.strategyManagerProgram) {
            const pdaAddress = await (sdk.solana.strategyManagerProgram as any).findStrategyPda(
              new PublicKey(token.address),
            );
            const { amount: amt } = await getTokenAmount(token.address, pdaAddress);
            totalDepositAmount = Amount.format(amt.toString(), token.decimals);
          } else if (token.chain === 'near' && sdk.near?.strategyManagerContract) {
            const near = await getNear();
            const contractId = config.allstakeSdkOptions.near.contracts.strategyManagerContractId;
            const account = await near.account(contractId);
            const amt = await account.viewFunction({
              contractId: token.address,
              methodName: 'ft_balance_of',
              args: {
                account_id: contractId,
              },
            });
            totalDepositAmount = Amount.format(amt.toString(), token.decimals);
          } else if (token.chain === 'eth' && sdk.ethereum?.uiDataProviderContract) {
            const strategyTokenBalances =
              await sdk.ethereum.uiDataProviderContract.strategyTokenBalances();
            const stakedTokenSumRaw = strategyTokenBalances
              .filter(
                (strategyTokenBalance) =>
                  strategyTokenBalance.token.toUpperCase() === token.address.toUpperCase(),
              )
              .reduce((prev, cur) => {
                return prev + cur.balance;
              }, BigInt(0));
            totalDepositAmount = Amount.format(stakedTokenSumRaw.toString(), token.decimals);
          }
          const formattedTotalDepositAmount = formatTotalDepositAmount(totalDepositAmount);

          return {
            symbol: token.symbol,
            icon: token.icon,
            title: `$${token.symbol} ${t('restake.restaked')}`,
            value: `${formattedTotalDepositAmount} ${token.symbol}`,
            valueInUsd: `~$${formatTvl(price, totalDepositAmount)}`,
            valueInUsdRaw: price ? price.times(totalDepositAmount) : BigNumber(0),
            price: price,
            boostTag: token.boostTag,
          };
        }),
    );

    return cards.sort((card1, card2) => card2.valueInUsdRaw.minus(card1.valueInUsdRaw).toNumber());
  }, [sdk, t]);

  // init fetch
  useEffect(() => {
    if (!sdk) return;
    if (fetchingRef.current) return;
    fetchingRef.current = true;
    fetchGalleryCards()
      .then((newGalleryCards) => newGalleryCards && setGalleryCards([...newGalleryCards]))
      .catch(console.error)
      .finally(() => {
        fetchingRef.current = false;
      });
  }, [sdk, balanceUpdateFlag, fetchGalleryCards]);

  return galleryCards;
}

import {
  Amount,
  BN,
  MultiSendAdapter,
  stakeToLinear,
  stakeToMsol,
  stakeToStnear,
} from 'allstake-sdk';
import { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Address } from 'viem';
import { useAccount } from 'wagmi';

import config from '@/config';
import { SymbolInput } from '@/config/type';
import { TGas } from '@/constants';
import { GlobalContainer } from '@/context/GlobalContext';
import { useWalletSelector } from '@/context/WalletSelectorContext';
import {
  approve,
  checkStrategyInsufficientAllowance,
  waitForTransactionReceiptRecursion,
} from '@/utils/erc20';
import { formatToReadableError } from '@/utils/error';
import { extractToTokenName, isChainNativeToken } from '@/utils/regex';
import { getTokenAmount } from '@/utils/rpc';
import { SECOND } from '@/utils/time';
import { isWebWallet } from '@/utils/wallet';
import { PublicKey } from '@solana/web3.js';

import { ModalTypes } from './types';

export interface CommonDepositRequest {
  tokenInput: string;
  symbolInput: SymbolInput;
}

export interface UseDepositProps {
  onStart?: () => void;
  onSuccess?: ({
    newTokenInput,
    newSymbolInput,
    modalType,
  }: {
    newTokenInput: string;
    newSymbolInput?: SymbolInput;
    modalType: ModalTypes;
  }) => void;
  onError?: (error: string, showModal: boolean) => void;
  onFinish?: () => void;
  onToggleLoadingModal?: (show: boolean) => void;
}

export function useDeposit({
  onSuccess,
  onError,
  onStart,
  onFinish,
  onToggleLoadingModal,
}: UseDepositProps) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const onSuccessRef = useRef(onSuccess);
  onSuccessRef.current = onSuccess;
  const onErrorRef = useRef(onError);
  onErrorRef.current = onError;
  const onStartRef = useRef(onStart);
  onStartRef.current = onStart;
  const onFinishRef = useRef(onFinish);
  onFinishRef.current = onFinish;
  const onToggleLoadingModalRef = useRef(onToggleLoadingModal);
  onToggleLoadingModalRef.current = onToggleLoadingModal;

  const { sdk: allstakeSdk, wallet, provider, publicClient } = GlobalContainer.useContainer();
  const ethAccount = useAccount();
  const { accountId, selector } = useWalletSelector();

  const onSolanaDeposit = useCallback(
    async ({ tokenInput, symbolInput }: CommonDepositRequest) => {
      if (!wallet?.publicKey) return;
      if (!allstakeSdk || !allstakeSdk.solana?.strategyManagerProgram) {
        return;
      }

      try {
        onStartRef.current?.();
        let amount = Amount.parse(tokenInput, symbolInput.decimals);
        if (isChainNativeToken(symbolInput.name)) {
          onToggleLoadingModalRef.current?.(true);
          const stakeToName = extractToTokenName(symbolInput);
          if (stakeToName === 'mSOL') {
            const beforeBalance = (
              await getTokenAmount(symbolInput.address, wallet.publicKey.toBase58())
            ).amount;
            await stakeToMsol(provider, new BN(amount));
            const afterBalance = (
              await getTokenAmount(symbolInput.address, wallet.publicKey.toBase58())
            ).amount;
            amount = String(afterBalance - beforeBalance);
            onToggleLoadingModalRef.current?.(false);
            navigate(`/?symbol=${stakeToName}`);
            const token =
              config.restakingTokens.find((token) => token.name === stakeToName) ??
              config.restakingTokens[0];
            const formattedAmount = Amount.format(amount, token.decimals);
            setTimeout(() => {
              onSuccessRef.current?.({
                newTokenInput: formattedAmount,
                modalType: 'restake_success',
              });
            }, SECOND * 1);
            return;
          } else if (stakeToName === 'jitoSOL') {
            // TODO: later
          }
        }

        await allstakeSdk.solana.strategyManagerProgram.deposit(
          new PublicKey(symbolInput.address),
          new BN(amount),
        );
        onSuccessRef.current?.({
          newTokenInput: '',
          modalType: 'deposit_success',
        });
      } catch (e) {
        console.error(e);
        onErrorRef.current?.(formatToReadableError(e, t), true);
        throw e;
      } finally {
        onFinishRef.current?.();
      }
    },
    [allstakeSdk, wallet, provider, navigate, t],
  );

  const onNearDeposit = useCallback(
    async ({ tokenInput, symbolInput }: CommonDepositRequest) => {
      if (!accountId) return;
      if (!allstakeSdk || !allstakeSdk.near?.strategyManagerContract) {
        return;
      }

      onStartRef.current?.();
      const isWebWalletStatus = await isWebWallet();

      try {
        let amount = Amount.parse(tokenInput, symbolInput.decimals);
        if (isChainNativeToken(symbolInput.name)) {
          const stakeToName = extractToTokenName(symbolInput);
          if (!isWebWalletStatus) {
            onToggleLoadingModalRef.current?.(true);
          }
          if (stakeToName === 'LiNEAR') {
            amount = await stakeToLinear(
              MultiSendAdapter.fromWalletSelector(selector),
              symbolInput.address,
              amount,
            );
          } else if (stakeToName === 'stNEAR') {
            amount = await stakeToStnear(
              MultiSendAdapter.fromWalletSelector(selector),
              symbolInput.address,
              amount,
            );
          }

          if (!isWebWalletStatus) {
            onToggleLoadingModalRef.current?.(false);
          }
          navigate(`/?symbol=${stakeToName}`);
          const targetSymbol = config.restakingTokens.find((token) => token.name === stakeToName);
          const token =
            config.restakingTokens.find((token) => token.name === stakeToName) ??
            config.restakingTokens[0];
          const formattedAmount = Amount.format(amount, token.decimals);
          setTimeout(() => {
            onSuccessRef.current?.({
              newTokenInput: formattedAmount,
              newSymbolInput: targetSymbol,
              modalType: 'restake_success',
            });
          }, SECOND * 1);
          return;
        }

        const strategies = await allstakeSdk.near.strategyManagerContract.getStrategies({});
        const strategy = strategies.find(
          (strategy) => strategy.underlying_token === symbolInput.address,
        );
        if (!strategy) {
          onErrorRef.current?.(t('error.strategyNotExist'), true);
          return;
        }

        await allstakeSdk.near.strategyManagerContract.depositIntoStrategy({
          strategyId: strategy.id,
          tokenId: symbolInput.address,
          amount,
          gas: TGas.times(100).toFixed(), // 100 TGas
        });
        onSuccessRef.current?.({
          newTokenInput: '',
          modalType: 'deposit_success',
        });
      } catch (e) {
        onErrorRef.current?.(formatToReadableError(e, t), !isWebWalletStatus);
        throw e;
      } finally {
        onFinishRef.current?.();
      }
    },
    [allstakeSdk, selector, accountId, navigate, t],
  );

  const onETHDeposit = useCallback(
    async ({ tokenInput, symbolInput }: CommonDepositRequest) => {
      if (!publicClient) return;
      if (!ethAccount?.address) return;

      if (
        !allstakeSdk ||
        !allstakeSdk.ethereum ||
        !allstakeSdk.ethereum.strategyManagerContract ||
        !allstakeSdk.ethereum.uiDataProviderContract
      ) {
        return;
      }

      try {
        onStartRef.current?.();
        const strategyData = await allstakeSdk.ethereum.uiDataProviderContract.strategyDataByToken(
          symbolInput.address,
        );
        if (
          await checkStrategyInsufficientAllowance(
            symbolInput,
            strategyData,
            ethAccount.address,
            tokenInput,
          )
        ) {
          await approve(
            symbolInput.address as Address,
            strategyData.strategyAddress as Address,
            publicClient,
          );
        }
        if (
          await checkStrategyInsufficientAllowance(
            symbolInput,
            strategyData,
            ethAccount.address,
            tokenInput,
          )
        ) {
          onErrorRef.current?.(t('error.insufficientAllowance'), true);
          return;
        }
        const txHash = await allstakeSdk.ethereum.strategyManagerContract.depositIntoStrategy(
          strategyData.strategyAddress,
          BigInt(Amount.parse(tokenInput, symbolInput.decimals)),
        );
        await waitForTransactionReceiptRecursion(publicClient, txHash);
        onSuccessRef.current?.({
          newTokenInput: '',
          modalType: 'deposit_success',
        });
      } catch (e) {
        onErrorRef.current?.(formatToReadableError(e, t), true);
        throw e;
      } finally {
        onFinishRef.current?.();
      }
    },
    [allstakeSdk, ethAccount, publicClient, t],
  );

  const onDeposit = useCallback(
    ({ tokenInput, symbolInput }: CommonDepositRequest) => {
      if (!symbolInput) return;

      if (symbolInput.chain === 'solana') {
        onSolanaDeposit({ tokenInput, symbolInput });
      } else if (symbolInput.chain === 'near') {
        onNearDeposit({ tokenInput, symbolInput });
      } else if (symbolInput.chain === 'eth') {
        onETHDeposit({ tokenInput, symbolInput });
      }
    },
    [onSolanaDeposit, onNearDeposit, onETHDeposit],
  );

  return {
    onSolanaDeposit,
    onNearDeposit,
    onETHDeposit,
    onDeposit,
  };
}

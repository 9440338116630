import { keyStores } from 'near-api-js';

import {
  AuroraImg,
  BSolImg,
  EzethImg,
  InfImg,
  JitoSolImg,
  LinearImg,
  MsolImg,
  NearImg,
  SolanaImg,
  StethImg,
  StNearImg,
} from '@/assets';

import { Config } from './type';

const config: Config = {
  near: {
    networkId: 'testnet',
    keyStore: new keyStores.BrowserLocalStorageKeyStore(),
    nodeUrl: 'https://neart.lava.build',
    walletUrl: 'https://testnet.mynearwallet.com',
    helperUrl: 'https://helper.testnet.near.org',
    headers: {},
  },
  walletConnectProjectId: 'b88c48420e260b9323017e1a43677f11',
  allstakeSdkOptions: {
    near: {
      networkId: 'testnet',
      contracts: {
        strategyManagerContractId: 'allstake-dev-3.testnet',
      },
    },
    solana: {
      networkId: 'devnet',
      programs: {
        strategyManagerProgramId: 'devczsK3cLi2H5q7rxvcbkUryYEnyygW4g4k6ySVwcZ',
      },
    },
    ethereum: {
      networkId: 'sepolia',
      contracts: {
        uiDataProviderContractId: '0x82cBfD99951997f91e94309FE2D68E8ee2da957e',
        strategyManagerContractId: '0x122BA97f667e18FF9f7019daA88736781d53615E',
      },
      pendle: {
        oracleAddress: '',
        ptTokenInfos: {},
      },
    },
  },
  pyth: {
    apiUrl: 'https://hermes.pyth.network/v2',
    priceFeedIds: {
      // chain token
      NEAR: 'c415de8d2eba7db216527dff4b60e8f3a5311c740dadb233e13e12547e226750', // NEAR
      SOL: 'ef0d8b6fda2ceba41da15d4095d1da392a0d2f8ed0c6c7bc0f4cfac8c280b56d', // SOL
      // eth token
      '0x0C764AC73927BD9B751d8309D2e84B12D36777ea':
        '0x06c217a791f5c4f988b36629af4cb88fad827b2485400a358f3b02886b54de92', // EZETH
      '0x989f32fa2D0aD3d819A6F3A81Ded4DBd00085a50':
        '0x846ae1bdb6300b817cee5fdee2a6da192775030db5615b94a465f53bd40850b5', // STETH
      // near token
      'aurora.fakes.testnet': '0x2f7c4f738d498585065a4b87b637069ec99474597da7f0ca349ba8ac3ba9cac5', // aurora
      // sol token
      mSoLzYCxHdYgdzU16g5QSh3i5K3z3KZK7ytfqcJm7So:
        'c2289a6a43d2ce91c6f55caec370f4acc38a2ed477f58813334c6d03749ff2a4', // MSOL
      Br58MYwHrmLNaBQ9H9teRxiuoXT5Soa6VyDo4RXYGACs:
        'c2289a6a43d2ce91c6f55caec370f4acc38a2ed477f58813334c6d03749ff2a4', // MSOL
      '2pMyhVRLquwdM6mWuNxWdqAUbjsYZpbu5wxg5Jj715bq':
        '67be9f519b95cf24338801051f9a808eff0a578ccb388db73b7f6fe1de019ffb', // JITOSOL
      '3XyWnYBpDHgKzx3skB9nsrEJS9yQCH8poMiuKbijKZ6V':
        '0x89875379e70f8fbadc17aef315adf3a8d5d160b811435537e03c97e8aac97d9c', // bSOL
      pGu8s4yyZnokmZpqSXHwdE3SeKqpRMz6LdnoErctSYn:
        '0xf51570985c642c49c2d6e50156390fdba80bb6d5f7fa389d2f012ced4f7d208f', // INF
    },
  },
  coingecko: {
    apiUrl: 'https://api.coingecko.com/api/v3',
    ids: {
      NEAR: 'near',
      SOL: 'solana',
      ETH: 'ethereum',
      mSoLzYCxHdYgdzU16g5QSh3i5K3z3KZK7ytfqcJm7So: 'msol',
      J1toso1uCk3RLmjorhTtrVwY9HJ7X8V9yYac6Y7kGCPn: 'jito-staked-sol',
    },
  },
  restakingTokens: [
    {
      chain: 'eth',
      icon: StethImg,
      symbol: 'stETH',
      name: 'stETH',
      decimals: 18,
      address: '0x989f32fa2D0aD3d819A6F3A81Ded4DBd00085a50',
      minAmount: 0.0001,
      showInGallery: true,
      showInStakeList: true,
      showInUnstakeList: true,
      selectTag: ['eth', 'LRT'],
      boostTag: [],
    },
    {
      chain: 'eth',
      icon: EzethImg,
      symbol: 'ezETH',
      name: 'ezETH',
      decimals: 18,
      address: '0x0C764AC73927BD9B751d8309D2e84B12D36777ea',
      minAmount: 0.0001,
      showInGallery: true,
      showInStakeList: true,
      showInUnstakeList: true,
      url: 'https://app.renzoprotocol.com/restake',
      selectTag: ['eth', 'LRT'],
      boostTag: [],
    },
    {
      chain: 'near',
      icon: LinearImg,
      symbol: 'LiNEAR',
      name: 'LiNEAR',
      decimals: 24,
      address: 'linear-protocol.testnet',
      minAmount: 0.1,
      url: 'https://app.linearprotocol.org/',
      showInGallery: true,
      showInStakeList: true,
      showInUnstakeList: true,
      selectTag: ['near', 'LST'],
      boostTag: ['1.5x Allstake multiplier', '5x Bedrock diamonds'],
    },
    {
      chain: 'near',
      icon: StNearImg,
      symbol: 'stNEAR',
      name: 'stNEAR',
      decimals: 24,
      address: 'meta-v2.pool.testnet',
      minAmount: 0.1,
      url: 'https://www.metapool.app/stake/?token=near',
      showInGallery: true,
      showInStakeList: true,
      showInUnstakeList: true,
      selectTag: ['near', 'LST'],
      boostTag: [],
    },
    {
      chain: 'near',
      icon: AuroraImg,
      symbol: 'Aurora',
      name: 'Aurora',
      decimals: 18,
      address: 'aurora.fakes.testnet',
      minAmount: 0.1,
      showInGallery: true,
      showInStakeList: true,
      showInUnstakeList: true,
      selectTag: ['near', 'Governance'],
      boostTag: [],
    },
    {
      chain: 'near',
      icon: NearImg,
      symbol: 'NEAR',
      name: 'NEAR~LiNEAR',
      decimals: 24,
      address: 'linear-protocol.testnet',
      minAmount: 1,
      showInGallery: false,
      showInStakeList: true,
      showInUnstakeList: false,
      selectTag: ['near', 'Native'],
      boostTag: ['1.5x Allstake multiplier', '5x Bedrock diamonds'],
    },
    {
      chain: 'near',
      icon: NearImg,
      symbol: 'NEAR',
      name: 'NEAR~stNEAR',
      decimals: 24,
      address: 'meta-v2.pool.testnet',
      minAmount: 1,
      showInGallery: false,
      showInStakeList: true,
      showInUnstakeList: false,
      selectTag: ['near', 'Native'],
      boostTag: [],
    },
    {
      chain: 'solana',
      icon: MsolImg,
      symbol: 'mSOL',
      name: 'mSOL',
      decimals: 9,
      address: 'mSoLzYCxHdYgdzU16g5QSh3i5K3z3KZK7ytfqcJm7So',
      minAmount: 0.01,
      url: 'https://marinade.finance/app/stake/?input=SOL',
      showInGallery: true,
      showInStakeList: true,
      showInUnstakeList: true,
      selectTag: ['solana', 'LST'],
      boostTag: [],
    },
    {
      chain: 'solana',
      icon: JitoSolImg,
      symbol: 'jitoSOL',
      name: 'jitoSOL',
      decimals: 9,
      address: '2pMyhVRLquwdM6mWuNxWdqAUbjsYZpbu5wxg5Jj715bq',
      minAmount: 0.01,
      url: 'https://www.jito.network/staking/',
      showInGallery: true,
      showInStakeList: true,
      showInUnstakeList: true,
      selectTag: ['solana', 'LST'],
      boostTag: [],
    },
    {
      chain: 'solana',
      icon: InfImg,
      symbol: 'INF',
      name: 'INF',
      decimals: 9,
      address: 'pGu8s4yyZnokmZpqSXHwdE3SeKqpRMz6LdnoErctSYn',
      minAmount: 0.01,
      url: 'https://app.sanctum.so/infinity',
      showInGallery: true,
      showInStakeList: true,
      showInUnstakeList: true,
      selectTag: ['solana', 'LST'],
      boostTag: [],
    },
    {
      chain: 'solana',
      icon: BSolImg,
      symbol: 'bSOL',
      name: 'bSOL',
      decimals: 9,
      address: '3XyWnYBpDHgKzx3skB9nsrEJS9yQCH8poMiuKbijKZ6V',
      minAmount: 0.01,
      url: 'https://stake.solblaze.org/app',
      showInGallery: true,
      showInStakeList: true,
      showInUnstakeList: true,
      selectTag: ['solana', 'LST'],
      boostTag: [],
    },
    {
      chain: 'solana',
      icon: SolanaImg,
      symbol: 'SOL',
      name: 'SOL~mSOL',
      decimals: 9,
      address: 'mSoLzYCxHdYgdzU16g5QSh3i5K3z3KZK7ytfqcJm7So',
      minAmount: 0.1,
      showInGallery: false,
      showInStakeList: true,
      showInUnstakeList: false,
      selectTag: ['solana', 'Native'],
      boostTag: [],
    },
    // {
    //   chain: "solana",
    //   icon: SolanaImg,
    //   symbol: "SOL",
    //   name: "SOL-jitoSOL",
    //   decimals: 9,
    //   address: "2pMyhVRLquwdM6mWuNxWdqAUbjsYZpbu5wxg5Jj715bq",
    //   minAmount: 0.1,
    //   url: "https://www.jito.network/staking/",
    //   showInGallery: false,
    //   showInStakeList: true,
    //   showInUnstakeList: false,
    // },
  ],
  pointsApiUrl: 'https://points-api-testnet.allstake.org',
  supportNetworks: ['near', 'solana', 'eth'],
  dropdownAssets: ['Native', 'LST', 'LRT', 'Governance', 'Stablecoin', 'Pendle'],
  dropdownNetworks: ['eth', 'near', 'solana', 'btc'],
  twitterApiServer:
    import.meta.env.VITE_DEV_TWITTER_API_SERVER_URL ||
    'https://stag-advanced-nationally.ngrok-free.app',
};

export default config;
